.carousel-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.carousel-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  user-select: none;
}

.carousel-arrow-icon {
  font-size: 30px;
}

.carousel-dots-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carousel-dot-icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}

.carousel-dot-active-icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #de684f;
  transition: 0.5s;
}

.carousel-dot-mr {
  margin-right: 1rem;
}

.carousel-dot-mr:last-child {
  margin-right: 0px;
}
