.chart-buttons-section {
  position: absolute;
  left: calc(50% - 100px);
  padding-top: 0px !important;
}

.chart-button-container {
  padding: 3px;
  border-radius: 4px;
  background-color: #2a2a2a;
  transition: background-color 0.3s ease;
  margin-right: 5px;
  font-size: 14px;
}

.chart-button-container:last-child {
  margin-right: 0;
}

.chart-button-container:hover {
  background-color: #3a3a3a;
}

.chart-canvas-border {
  border-top: solid 1px #454d55 !important;
}

.chart-legends-container {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 10px;
  padding: 5px 10px 0;
  margin-top: 30px;
  font-size: 13px;
  justify-content: start;
  text-align: left;
}

.chart-legends-content {
  display: flex;
  align-items: center;
}

.legend-square {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-color: #ffffff;
  border-radius: 3px;
}

@media (min-width: 1000px) and (max-width: 1400px) {
  #chart-canvas {
    max-height: 175px;
  }
}

@media (min-width: 1700px) {
  #chart-canvas {
    max-height: 280px;
  }
}
