.devices-info {
	height: 100%;
	overflow-y: scroll;
}

.device-name-column {
	font-size: small;
	width: 14%;
}

.battery-column {
	font-size: small;
	width: 10%;
}
.temperature-column {
	font-size: small;
	width: 0.1%;
}
.provider-column {
	font-size: small;
	width: 10%;
}
.network-type-column {
	font-size: small;
	width: 4%;
}
.signal-strength-column {
	font-size: small;
	width: 10%;
}

.table th,
.table td {
	padding: 0.6rem; 
	border: 0.75px solid #353638;
}
.lower-rows th,
.lower-rows td {
	padding: 0.3rem 0.6rem;
}

