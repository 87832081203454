/* .links-container {
  position: relative;
  font-size: 1.1rem;
} */

.navbar-brand {
  /* top: 3px; */
  position: relative;
}

.navbar-omni {
  /* background-color: #dadada; */
  color: #f4efe9 !important;
}

.seperator {
  /* background-color: #b4b4b4; */
  background-color: #515151;
  height: 50px;
  width: 0.5px;
}

.links-container a {
  color: #999999 !important;
  font-weight: 400;
}

.links-container a.active {
  color: #f4efe9 !important;
  font-weight: 400;
}

.dropdown-menu {
  position: relative;
}

.dropdown-item {
  color: #f4efe9 !important;
}

.dropdown-menu a:hover {
  background-color: rgb(41, 41, 41) !important;
}

.dropdown-item span {
  font-size: 21px;
  padding-right: 5px;
  padding-top: 2px;
  position: relative;
  top: 5px;
}

.omni-logo {
  width: 220px;
  height: 50px;
  margin-left: 5px !important;
  margin-right: 35px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.notification-container {
  z-index: 9999 !important;
  white-space: nowrap !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.navbar-nav {
  padding-left: 20px !important;
}

.danger-submit-btn,
.danger-submit-btn:active .danger-submit-btn:hover {
  border-color: #de684f !important;
  background-color: #de684f !important;
  font-weight: 500;
}

.danger-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-body {
  font-size: 0.9vw !important;
  font-weight: 500 !important;
}

.modal-body-viewer {
  text-align: left !important;
}

.modal-body-logout,
.modal-body-guests-reach-limit {
  text-align: center !important;
}

.modal-body-alert {
  text-align: center !important;
}

.create-guest-mode-modal-button {
  display: flex;
  align-items: center;
  height: 100%;
  color: #999999;
  font-weight: 400;
  cursor: pointer;
}

.user-container {
  position: relative;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.username-circle {
  background-color: #202020 !important;
  color: white;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 10px;
}

.company-name {
  display: inline-block;
  max-width: calc(100% - 50px); /* Adjust based on the size of username-circle */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 50px;
  transform: translateX(-85%);
  background-color: #272727;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: white !important;
  width: auto;
  min-width: 250px;
  max-width: 500px;
  padding: 10px;
  box-sizing: border-box;
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-menu-item:hover {
  background-color: #474747;
}

.dropdown-menu-social-icons {
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
}

.logout-icon {
  cursor: pointer;
}

.dropdown-user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-menu-item .material-icons {
  margin-right: 8px;
}

.dropdown-user-info {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.separator {
  border-top: 1px solid #212121;
  margin: 4px 0;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}

/* Company name below username */
/* .companyName {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-transform: lowercase !important;
} */

.dropdown-menu-social-icons {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.dropdown-menu-social-icons svg {
  border: none !important;
}

.dropdown-menu-social-icons a {
  color: #838383;
  font-size: 18px;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  background-color: transparent !important;
}

.dropdown-menu-social-icons a:hover {
  background-color: transparent !important;
}
