.output {
	display: none;
	margin-left: 0.5vw;
	margin-right: 0.5vw;
	margin-bottom: 1vh !important;
	background-color: #202020;
	border: 1px solid #181818;
	box-shadow: 0 4px 10px 1px rgba(0, 0, 1, 0.1), 0 0px 15px 5px rgba(0, 0, 0, 0.12);

}

.critical-alarm-thumbnail {
	--thumbnailWidth: 203px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background-color: #202020;
	cursor: pointer;
}

.critical-alarm-icon {
	font-size: 7em;
}

.warning-alarm-icon-container {
	bottom: 20%;
	position: absolute;
	left: 85%;
	text-shadow: 0 0 5px black;
}
