.root {
  display: flex;
  justify-content: center;
  column-gap: 12px;
}

.step_root {
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: #1D1E20;
}

.curent_step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #EFF0F6;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  transition: background-color 0.2s, color 0.2s;
}

.line_step {
  position: relative;
  width: 108px;
  height: 6px;
  border-radius: 40px;
  background-color: #EFF0F6;
  overflow: hidden;
}

.line_step::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #DE684F;
  transition: width 0.2s;
}

.line_step_fill::before {
  width: 50%;
}

.line_step_fill_whole::before {
  width: 100%;
}

.curent_step_fill {
  background-color: #DE684F;
  color: #FFFFFF;
}

.curent_step_fill_whole {
  background-color: #DE684F;
  color: #FFFFFF;
}