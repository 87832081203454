/* 
Omnistream logo dark blue: #457cbc
Omnistream logo light blue: #5cc2ee
font color 	#f4efe9

*/
/* ------------------------------------------------------------------
                              Color Guide

Basic blue:                   #457cbc
Lighter (10%) blue:           #6996c9
More lighter (30%) blue:      #b4cae4
Even more lighter (40%) blue: #dae5f1
Darker (10%) blue:            #366396

Grey 1 (80% lighter black):   ##d1d1d1
Grey 2 (40% lighter black):   #666666
Grey 3:                       #7d8891
Grey 4:                       #495057

App background color:			#f2f2f2
------------------------------------------------------------------- */

/* ------------------------------------------------------------------
                  Bootstrat default color overwriting
------------------------------------------------------------------- */
.bg-info,
.btn-info,
.badge-info {
	background-color: #DE684F !important;
	border-color: #3e3e3e !important;
	/* font-size: 1rem; */
	color: white;
}

/* not must */
/* .badge{
	background-color: #DE684F;
	border: none !important;
} */

.btn-white {
	color: white;
}

.btn-white:hover {
	color: rgb(255, 255, 255);
}

.btn-black {
	color: black;
}

.btn-black:hover {
	color: rgb(255, 255, 255);
}

/* audio sources dropdown */
.btn-background {
	background-color: #272A2C;
	color: white;
}

.btn-background:hover {
	background-color: #272A2C;
	color: #de684f;
}

.btn-general {
	background-color: transparent;
	color: #DE684F;
	border: none;
	outline: 0 !important;
	outline-style: none;
}

.btn-general-disabled {
	background-color: transparent;
	color: #f4efe9;
	border: none;
	outline: 0 !important;
	outline-style: none;
}

.temp-cool {
	background-color: #0078FF !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-warn {
	background-color: #ffc107 !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-critical {
	background-color: red !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-background {
	background-color: #7d8891;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.text-info {
	/*	color: #585858 !important; */
	color: #f4efe9 !important;

}



.btn-info.disabled,
.btn-info:disabled {
	background-color: #7d8891 !important;
	/* border-color: #d1d1d1 !important; */
}

.btn-info:hover {
	color: #ffffff !important;
	/* border-color: #d1d1d1 !important; */
}

.table .thead-dark th {
	background-color: #202020 !important;
	padding-top: 15px;
	padding-bottom: 15px;
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: #d1d1d1 !important;
	background-color: #DE684F !important;
}

input {
	background-color: #272a2c !important;
	border: none !important;
}

input:focus {
	background-color: #272a2c !important;
	border: none !important;
}

a {
	color: #cacaca;
	font-weight: 400;
}

a:hover {
	color: #b1dffd;
}

.tooltip-inner {
	background-color: #DE684F;
}

/* ------------------------------------------------------------------
                           Global styles
------------------------------------------------------------------- */

.app {
	/* cursor: none; */
	height: 100vh;
	background-color: #181818;
}

.data-area {
	background-color: #1d1e20 !important;
	color: #f4efe9;
	/* background-color: #1f2022; */
	height: 40vh;
	border: 0.75px solid #3e403f;
	border-radius: 0 0.35rem 0.35rem 0.35rem;
	overflow-y: auto;
}

.card {
	border: none !important;
}

.card-header {
	background-color: #7d8891;
	border: 0px solid #3e403f;
	padding: 0.15rem 0;
}

.card-header:first-child {
	border: none !important;
	background-color: #202020;
}

.card-header-disabled {
	background-color: #dadada;
	padding: 0.15rem 0;
	pointer-events: none
}

.card-body {
	background-color: #121212;
	padding: 0.4rem 1.25rem;
}

.tooltip-inner {
	border: 1.5px solid #212529;
}

.alert {
	margin-bottom: 0 !important;
}

.click-item {
	cursor: pointer;
}

button:focus,
button:hover {
	outline: none;
}

.text-font {
	font-size: small;
}

.center-icon {
	font-size: 1.1rem;
	margin-right: 3px;
	margin-top: 4px;
	display: block !important;
	pointer-events: none;
	cursor: default;
}

.modal-dialog,
.modal-content {
	color: #f4efe9 !important;
	background-color: #202020 !important;
	border: 1px solid #3e403f !important;
	border-radius: 10px;
	/* filter: invert(1) !important; */
}

.close {
	color: #f4efe9 !important;
}

.modal-body {
	border: none !important;
}

.parent-class {
	position: relative;
}

/* Countries list background color */

.react-tel-input .country-list .country.highlight {
	background-color: #DE684F !important;
}

/* Countries dial code styles */

.react-tel-input .country-list .country .dial-code {
	color: white !important;
}

/* Countries list text size */

.react-tel-input .country-list .country-name {
	font-size: 15px;
}