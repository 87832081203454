.broadcast-settings ul,
.broadcast-settings .data-area {
	margin-left: 0.5vw;
	
}

#settings-сontrol {
	width: 9.6vw;
}

#rtmp,
#output-association {
	width: 6vw;

}

#outputs-save-btn {
	margin-top: 3vh;
	width: 8vw;
}

.broadcast-settings-padding{
	padding-right: 0px !important;
}