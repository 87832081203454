@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.container {
    width: 100%;
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
}

.wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 9.55px;
}

.option {
    width: 130px;
    height: 78px; /* old values was: 43px (for showing more buttons include AMS) */
    background: #181818;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    border: 0.5px solid #DE684F;
    align-items: center;
    cursor: pointer;
}

.optionText {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
}

.mainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 453px;
    height: 253px;
    border-radius: 10px;
    border: 1.5px solid #DE684F;
    background: #181818;
    padding: 0 18px;
}

.mainContentText {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: center;
}

.active {
    background: #DE684F;
}
