.control-buttons button {
	/* padding: 0.5rem; */
}

.control-buttons .col {
	padding-left: 0.1rem;
}

.dropdown-item span {
	margin-left: 3px;
	margin-right: 3px;
	font-size: 15px;
}

.dropdown-menu-no-padding{
	background-color: rgb(15, 15, 15) !important;
	padding: 0;
	z-index: 999;
	width: auto !important;
}

.hr {
	background:transparent;
	border-bottom:2px solid #272A2C;
  }
