.root {
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 21px;
}

.field {
  display: flex;
  column-gap: 21px;
  justify-content: center;
}