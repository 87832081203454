.logs-section {
	position: relative;
	flex-direction: column;
	display: flex;
	height: 34vh;
	width: 100%;
	overflow-y: scroll;
	border: 0.75px solid #3e403f;
	border-radius: 0.35rem;
}

.logs-section ul {
	margin-top: 22px;
	/* margin-block-end: 5px; */
	width: 100%;
	position: absolute;
	padding-left: 0.4vw;
	list-style-type: none;
}

.clear-btn {
	color: #f4efe9 !important;
	width: fit-content;
	display: inline-block;
}

.clear-btn:focus {
	outline:none;
	box-shadow: none;
  }

  /* .clear-btn:hover {
	color: #b1dffd;
  } */

/* custom scroller for logs scrollbar */
.logs-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #202020;}
.logs-scrollbar::-webkit-scrollbar-button {background-color: #202020; }
.logs-scrollbar::-webkit-scrollbar-track {background-color: #202020;}
.logs-scrollbar::-webkit-scrollbar-track-piece {background-color: #202020;}
.logs-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.logs-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.logs-scrollbar::-webkit-resizer {background-color: #666;}
