.content_container {
    padding: 21px 62px 37px;
    width: 745px;
    height: 498px;
    background: #1D1E20 !important;
    border-radius: 25px !important;
    border: none !important;
}

.button_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 43px;
}
