#notification {
	display: none;
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;
	background-color: #343a40;
	border: 1px solid #2c2f31 !important;
}

#close_btn {
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;

}

#close_btn:hover {
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;
}