.alarm-icon-container-absolute {
  position: absolute;
  padding: 3px;
  right: 0px;
  /* padding: 5px; */
}

.alarm-icon-container-relative {
  /* position: relative; */
  padding: 3px;
  right: 0px;
  /* padding: 5px; */
}

.alarm-icon {
  padding-right: 0.3rem;
}

.alarm-icon-tooltip-down .tooltip-message {
  top: 120%;
}

.alarm-icon-tooltip-up .tooltip-message {
  bottom: 125%;
}

.tooltip-message {
  visibility: hidden;
  position: absolute;
  left: -315%;
  z-index: 3;
  width: 203px;
  border: 1.5px solid #f4efe9;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  background-color: #de684f;
  color: #f4efe9;
}

/* -------------- Tooltip arrow styles start -------------- */
.alarm-icon-tooltip-down .tooltip-message::after {
  content: ' ';
  position: absolute;
  bottom: 95%;
  margin-left: 70px;
  border-width: 0 8px 12px 8px;
  border-style: solid;
  border-color: transparent transparent #de684f transparent;
}

.alarm-icon-tooltip-down .tooltip-message::before {
  content: ' ';
  position: absolute;
  bottom: 102%;
  right: 14px;
  margin-left: -5px;
  border-width: 0 8px 12px 8px;
  border-style: solid;
  border-color: transparent transparent #f4efe9 transparent;
}

.alarm-icon-tooltip-up .tooltip-message::after {
  top: 100%;
  border-color: #f4efe9 transparent transparent transparent;
  border-width: 7px !important;
}

.tooltip-message::after {
  position: absolute;
  left: 50%;
  margin-left: -2.5%;
  content: ' ';
  /* border-width: 0.5rem; */
  border-style: solid;
}
/* -------------- Tooltip arrow styles end -------------- */

.show-tooltip {
  visibility: visible;
}
