.form-group {
	margin: 0.7rem;
}

.form-control {
	color: #f4efe9 !important;
	border-radius: 5px !important;
	height: 35px !important;
}

.form-group .material-icons {
	background-color: #181818 !important;
	border: none !important;
	color: #f4efe9 !important;
	cursor: pointer;
}

.form-group {
	margin: 0.7rem;
}

.form-group .material-icons {
	background-color: #181818 !important;
	border: none !important;
	color: #f4efe9 !important;
	cursor: pointer;
}

.form-group .material-icons:active {
	background-color: #181818 !important;
	border: none !important;
}

.form-control {
	color: #f4efe9 !important;
	border-radius: 5px !important;
	height: 35px !important;
}

.text-input {
	margin-bottom: 1rem;
}

.input-container {
	position: relative;
}

.input-container .form-control {
	width: 100%;
	padding-right: 40px;
}

.input-container .custom-element {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	color: #f4efe9;
}

.input-container .custom-element:hover,
.input-container .custom-element:active {
	color: #e05e41 !important;
}

.input-container .form-control {
	width: 100%;
	padding-right: 40px;
	/* Adjust padding to make space for the custom element */
}

.input-container .custom-element {
	position: absolute;
	top: 75%;
	right: 15px;
	transform: translateY(-50%);
	cursor: pointer;
	color: #f4efe9;
}