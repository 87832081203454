.container {
  display: flex;
  width: 244px;
  column-gap: 4px;
}

.input {
  height: 50px !important;
  background-color: #272a2c !important;
  border: none !important;
  color: white !important;
}

.input>input {
  color: white !important;
  outline: none !important;
}

.error {
  color: #ff5f5f;
  font-size: 13px;
}

.phoneInput {
  width: 244px !important;
  background-color: #272a2c;
  border: none;
  color: white !important;
  margin-left: 0px !important;
  font-size: 10px;
  line-height: 1.42857143;
  border-radius: 4px;
}

.phoneInput input {
  width: 244px !important;
  color: white !important;
  outline: none;
}

.phoneInput:focus {
  width: 244px !important;
  border-color: #05f168;
  outline: 0;
  box-shadow: none;
}

.phoneInputDropdown {
  width: 244px !important;
  border: 1px solid #141414 !important;
  border-radius: 4px;
  background-color: #272a2c !important;
  color: white !important;
}

.phoneInputFlagDropdown {
  background-color: #272a2c !important;
  border: none;
}

.phoneInputSearch {
  background-color: #272a2c !important;
  color: white !important;
  border-bottom: 1px solid #141414;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 10px;
  padding: 6px 12px;
  width: 100%;
}

/* Countries list background color */
.phoneInputDropdown li:hover {
  background-color: #DE684F !important;
}

.phoneInputDropdown li:nth-child(1):hover {
  background-color: #272a2c !important;
  cursor: text;
}

.phoneInputDropdown .dropdown-menu .dropdown-item:hover {
  background-color: #DE684F !important;
}

.phoneInputSearch input {
  color: white !important;
  width: 195px !important;
  height: 30px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  padding-left: 10px !important;
}

.phoneInput .search-box input {
  color: white !important;
}
