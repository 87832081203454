.dark-mode{
  background-color: #181818 !important;

}

.tab-pointer {
  cursor: pointer;
  background-color: #181818;
}

.btn-icon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon-pending-alarms {
  color: #dc3545;
}

.icon-pending-alerts {
  color: #ffc107 !important;
}

.is-exist-export {
  height: 8px;
  width: 8px;
  background-color: #10fc27;
  border-radius: 100%;
  position: absolute;
  margin-top: -1px;
  margin-left: -1px;
}

/* custom scroller for records scrollbar */
.records-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-button {
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-track {
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-track-piece {
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
.records-scrollbar::-webkit-scrollbar-corner {
  background-color: #646464;
}
.records-scrollbar::-webkit-resizer {
  background-color: #666;
}
