@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
/* 
Omnistream logo dark blue: #457cbc
Omnistream logo light blue: #5cc2ee
font color 	#f4efe9

*/
/* ------------------------------------------------------------------
                              Color Guide

Basic blue:                   #457cbc
Lighter (10%) blue:           #6996c9
More lighter (30%) blue:      #b4cae4
Even more lighter (40%) blue: #dae5f1
Darker (10%) blue:            #366396

Grey 1 (80% lighter black):   ##d1d1d1
Grey 2 (40% lighter black):   #666666
Grey 3:                       #7d8891
Grey 4:                       #495057

App background color:			#f2f2f2
------------------------------------------------------------------- */

/* ------------------------------------------------------------------
                  Bootstrat default color overwriting
------------------------------------------------------------------- */
.bg-info,
.btn-info,
.badge-info {
	background-color: #DE684F !important;
	border-color: #3e3e3e !important;
	/* font-size: 1rem; */
	color: white;
}

/* not must */
/* .badge{
	background-color: #DE684F;
	border: none !important;
} */

.btn-white {
	color: white;
}

.btn-white:hover {
	color: rgb(255, 255, 255);
}

.btn-black {
	color: black;
}

.btn-black:hover {
	color: rgb(255, 255, 255);
}

/* audio sources dropdown */
.btn-background {
	background-color: #272A2C;
	color: white;
}

.btn-background:hover {
	background-color: #272A2C;
	color: #de684f;
}

.btn-general {
	background-color: transparent;
	color: #DE684F;
	border: none;
	outline: 0 !important;
	outline-style: none;
}

.btn-general-disabled {
	background-color: transparent;
	color: #f4efe9;
	border: none;
	outline: 0 !important;
	outline-style: none;
}

.temp-cool {
	background-color: #0078FF !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-warn {
	background-color: #ffc107 !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-critical {
	background-color: red !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-background {
	background-color: #7d8891;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.text-info {
	/*	color: #585858 !important; */
	color: #f4efe9 !important;

}



.btn-info.disabled,
.btn-info:disabled {
	background-color: #7d8891 !important;
	/* border-color: #d1d1d1 !important; */
}

.btn-info:hover {
	color: #ffffff !important;
	/* border-color: #d1d1d1 !important; */
}

.table .thead-dark th {
	background-color: #202020 !important;
	padding-top: 15px;
	padding-bottom: 15px;
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: #d1d1d1 !important;
	background-color: #DE684F !important;
}

input {
	background-color: #272a2c !important;
	border: none !important;
}

input:focus {
	background-color: #272a2c !important;
	border: none !important;
}

a {
	color: #cacaca;
	font-weight: 400;
}

a:hover {
	color: #b1dffd;
}

.tooltip-inner {
	background-color: #DE684F;
}

/* ------------------------------------------------------------------
                           Global styles
------------------------------------------------------------------- */

.app {
	/* cursor: none; */
	height: 100vh;
	background-color: #181818;
}

.data-area {
	background-color: #1d1e20 !important;
	color: #f4efe9;
	/* background-color: #1f2022; */
	height: 40vh;
	border: 0.75px solid #3e403f;
	border-radius: 0 0.35rem 0.35rem 0.35rem;
	overflow-y: auto;
}

.card {
	border: none !important;
}

.card-header {
	background-color: #7d8891;
	border: 0px solid #3e403f;
	padding: 0.15rem 0;
}

.card-header:first-child {
	border: none !important;
	background-color: #202020;
}

.card-header-disabled {
	background-color: #dadada;
	padding: 0.15rem 0;
	pointer-events: none
}

.card-body {
	background-color: #121212;
	padding: 0.4rem 1.25rem;
}

.tooltip-inner {
	border: 1.5px solid #212529;
}

.alert {
	margin-bottom: 0 !important;
}

.click-item {
	cursor: pointer;
}

button:focus,
button:hover {
	outline: none;
}

.text-font {
	font-size: small;
}

.center-icon {
	font-size: 1.1rem;
	margin-right: 3px;
	margin-top: 4px;
	display: block !important;
	pointer-events: none;
	cursor: default;
}

.modal-dialog,
.modal-content {
	color: #f4efe9 !important;
	background-color: #202020 !important;
	border: 1px solid #3e403f !important;
	border-radius: 10px;
	/* filter: invert(1) !important; */
}

.close {
	color: #f4efe9 !important;
}

.modal-body {
	border: none !important;
}

.parent-class {
	position: relative;
}

/* Countries list background color */

.react-tel-input .country-list .country.highlight {
	background-color: #DE684F !important;
}

/* Countries dial code styles */

.react-tel-input .country-list .country .dial-code {
	color: white !important;
}

/* Countries list text size */

.react-tel-input .country-list .country-name {
	font-size: 15px;
}
.login-page {
  height: 100vh;
  background-color: #181818;
}

.login-form {
  min-height: 300px;
  color: #f4efe9 !important;
  background-color:#1a1b1b;
  width: 28vw;
  height: 32vw;
  margin-top: -5vh;
  border-radius: 1rem;
  padding-top: 4vh;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 1, 0.1), 0 0px 15px 5px rgba(0, 0, 0, 0.12);
}

.login-form input {
  background-color: #181818 !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.login-form input:focus {
  background-color: #181818 !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.login-form input:valid {
  background-color: #181818 !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #181818 inset !important;
  -webkit-text-fill-color: #f4efe9 !important;
}

.error-message {
  margin: 2vh;
  text-align: center;
}

#show-password-div {
  padding-left: 0.75vh;
  padding-bottom: 0.25vh;
}

#login-button {
  margin: 2vh;
  width: 11vw;
}

.version-txt{
  color: #4f5255; 
  margin-top: -20px;
  font-size: 14px !important;
}

.site-center-text{
  text-align: center !important;
  color: #4f5255; 
  margin-top: 0px;
  font-size: 14px !important;
}

.form-group {
	margin: 0.7rem;
}

.form-control {
	color: #f4efe9 !important;
	border-radius: 5px !important;
	height: 35px !important;
}

.form-group .material-icons {
	background-color: #181818 !important;
	border: none !important;
	color: #f4efe9 !important;
	cursor: pointer;
}

.form-group {
	margin: 0.7rem;
}

.form-group .material-icons {
	background-color: #181818 !important;
	border: none !important;
	color: #f4efe9 !important;
	cursor: pointer;
}

.form-group .material-icons:active {
	background-color: #181818 !important;
	border: none !important;
}

.form-control {
	color: #f4efe9 !important;
	border-radius: 5px !important;
	height: 35px !important;
}

.text-input {
	margin-bottom: 1rem;
}

.input-container {
	position: relative;
}

.input-container .form-control {
	width: 100%;
	padding-right: 40px;
}

.input-container .custom-element {
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	cursor: pointer;
	color: #f4efe9;
}

.input-container .custom-element:hover,
.input-container .custom-element:active {
	color: #e05e41 !important;
}

.input-container .form-control {
	width: 100%;
	padding-right: 40px;
	/* Adjust padding to make space for the custom element */
}

.input-container .custom-element {
	position: absolute;
	top: 75%;
	right: 15px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	cursor: pointer;
	color: #f4efe9;
}
.spinner-border-sm {
	width: 1.5em;
	height: 1.5em;
}

#notification {
	display: none;
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;
	background-color: #343a40;
	border: 1px solid #2c2f31 !important;
}

#close_btn {
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;

}

#close_btn:hover {
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;
}
/* .links-container {
  position: relative;
  font-size: 1.1rem;
} */

.navbar-brand {
  /* top: 3px; */
  position: relative;
}

.navbar-omni {
  /* background-color: #dadada; */
  color: #f4efe9 !important;
}

.seperator {
  /* background-color: #b4b4b4; */
  background-color: #515151;
  height: 50px;
  width: 0.5px;
}

.links-container a {
  color: #999999 !important;
  font-weight: 400;
}

.links-container a.active {
  color: #f4efe9 !important;
  font-weight: 400;
}

.dropdown-menu {
  position: relative;
}

.dropdown-item {
  color: #f4efe9 !important;
}

.dropdown-menu a:hover {
  background-color: rgb(41, 41, 41) !important;
}

.dropdown-item span {
  font-size: 21px;
  padding-right: 5px;
  padding-top: 2px;
  position: relative;
  top: 5px;
}

.omni-logo {
  width: 220px;
  height: 50px;
  margin-left: 5px !important;
  margin-right: 35px !important;
  background-repeat: no-repeat;
  background-position: center;
}

.notification-container {
  z-index: 9999 !important;
  white-space: nowrap !important;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.navbar-nav {
  padding-left: 20px !important;
}

.danger-submit-btn,
.danger-submit-btn:active .danger-submit-btn:hover {
  border-color: #de684f !important;
  background-color: #de684f !important;
  font-weight: 500;
}

.danger-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-body {
  font-size: 0.9vw !important;
  font-weight: 500 !important;
}

.modal-body-viewer {
  text-align: left !important;
}

.modal-body-logout,
.modal-body-guests-reach-limit {
  text-align: center !important;
}

.modal-body-alert {
  text-align: center !important;
}

.create-guest-mode-modal-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  color: #999999;
  font-weight: 400;
  cursor: pointer;
}

.user-container {
  position: relative;
}

.user-info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.username-circle {
  background-color: #202020 !important;
  color: white;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 10px;
}

.company-name {
  display: inline-block;
  max-width: calc(100% - 50px); /* Adjust based on the size of username-circle */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 50px;
  -webkit-transform: translateX(-85%);
          transform: translateX(-85%);
  background-color: #272727;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: white !important;
  width: auto;
  min-width: 250px;
  max-width: 500px;
  padding: 10px;
  box-sizing: border-box;
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-menu-item:hover {
  background-color: #474747;
}

.dropdown-menu-social-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 8px 0;
}

.logout-icon {
  cursor: pointer;
}

.dropdown-user-info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.dropdown-menu-item .material-icons {
  margin-right: 8px;
}

.dropdown-user-info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 8px 16px;
}

.separator {
  border-top: 1px solid #212121;
  margin: 4px 0;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}

/* Company name below username */
/* .companyName {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-transform: lowercase !important;
} */

.dropdown-menu-social-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 10px 0;
}

.dropdown-menu-social-icons svg {
  border: none !important;
}

.dropdown-menu-social-icons a {
  color: #838383;
  font-size: 18px;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  background-color: transparent !important;
}

.dropdown-menu-social-icons a:hover {
  background-color: transparent !important;
}

.GuestCounter_root__2i5EP {
  padding: 10px;
  height: 40px;
  min-width: 60px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  border-radius: 10px;
  cursor: default;
}
.GuestGetMoreGuestsModal_body__14MGo {
  white-space: pre-wrap !important;
  font-size: 0.9vw !important;
  font-weight: 500 !important;
  position: relative;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  border: none !important;
}

.GuestGetMoreGuestsModal_get-more-guests-modal__2hgH6 {
  width: 550px !important;
}
.GuestModeModal_modal__1Oxdi {
  background-color: transparent !important;
  border-radius: none !important;
  border: none !important;
}

@media (min-width: 576px) {
  .GuestModeModal_modal__1Oxdi {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 1.75rem auto;
  }
}

.GuestModeModal_modal__1Oxdi>div {
  background-color: #202020 !important;
  border-radius: 25px !important;
  border: none !important;
}

.GuestModeModal_modal__1Oxdi input {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.GuestModeModal_modal__1Oxdi input:valid {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

/* Autofill styles */
.GuestModeModal_modal__1Oxdi input:-webkit-autofill,
.GuestModeModal_modal__1Oxdi input:-webkit-autofill:hover,
.GuestModeModal_modal__1Oxdi input:-webkit-autofill:focus,
.GuestModeModal_modal__1Oxdi input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #272a2c inset !important;
  -webkit-text-fill-color: #f4efe9 !important;
}

/* Hover and focus styles */
.GuestModeModal_modal__1Oxdi input:hover,
.GuestModeModal_modal__1Oxdi input:active,
.GuestModeModal_modal__1Oxdi input:focus {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}


.GuestModeModal_content_container__3jJl5 {
  padding: 21px 62px 37px;
  width: 745px;
  height: 650px;
  background: #181818 !important;
  border-radius: 25px !important;
  border: none !important;
}

.GuestModeModal_title__2htmf {
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-weight: bold;
  color: #DE684F;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.GuestModeModal_subtitle__95q2D {
  margin-top: 16px;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  color: #D9DBE9;
}

.GuestModeModal_hint__2bohC {
  margin-top: 4px;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: italic;
  color: #D9DBE9;
}

.GuestModeModal_formContainer__2Lmpj {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.GuestModeModal_form__3PqXw {
  padding: 0 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-justify-content: center;
          justify-content: center;
}

.GuestModeModal_buttons__3Tl5H {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.GuestModeModal_buttons__3Tl5H>button {
  border-radius: 10px;
}

.GuestModeModal_stepper__2EDHt {
  margin-top: 11px;
  padding-bottom: 31px;
  border-bottom: 1px solid #D9DBE9;
}
#create-guest-mode-modal-dialog {
  background: none !important;
  border: none !important;
}


.GuestInfo_root__1ebZ4 {
  padding-top: 15px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 21px;
  row-gap: 21px;
}

.GuestInfo_field__19Mdo {
  display: -webkit-flex;
  display: flex;
  grid-column-gap: 21px;
  -webkit-column-gap: 21px;
          column-gap: 21px;
  -webkit-justify-content: center;
          justify-content: center;
}
.TextField_input__2kKPx {
  width: 244px;
  height: 50px !important;
}

.TextField_inputBlack__2Jhca {
  height: 50px !important;
  background: #1D1E20 !important;
}

.TextField_inputBlack__2Jhca:focus {
  background: #1D1E20 !important;
}

.TextField_smallHeight__3PA1k {
  height: 40px !important;
}

.TextField_error__1mZCk {
  color: #ff5f5f;
  font-size: 13px;
}
.PhoneNumberField_container__gStQx {
  display: -webkit-flex;
  display: flex;
  width: 244px;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
}

.PhoneNumberField_input__1RlWr {
  height: 50px !important;
  background-color: #272a2c !important;
  border: none !important;
  color: white !important;
}

.PhoneNumberField_input__1RlWr>input {
  color: white !important;
  outline: none !important;
}

.PhoneNumberField_error__3kOVk {
  color: #ff5f5f;
  font-size: 13px;
}

.PhoneNumberField_phoneInput__1pjAb {
  width: 244px !important;
  background-color: #272a2c;
  border: none;
  color: white !important;
  margin-left: 0px !important;
  font-size: 10px;
  line-height: 1.42857143;
  border-radius: 4px;
}

.PhoneNumberField_phoneInput__1pjAb input {
  width: 244px !important;
  color: white !important;
  outline: none;
}

.PhoneNumberField_phoneInput__1pjAb:focus {
  width: 244px !important;
  border-color: #05f168;
  outline: 0;
  box-shadow: none;
}

.PhoneNumberField_phoneInputDropdown__3nFCg {
  width: 244px !important;
  border: 1px solid #141414 !important;
  border-radius: 4px;
  background-color: #272a2c !important;
  color: white !important;
}

.PhoneNumberField_phoneInputFlagDropdown__1y_0E {
  background-color: #272a2c !important;
  border: none;
}

.PhoneNumberField_phoneInputSearch__2OvUY {
  background-color: #272a2c !important;
  color: white !important;
  border-bottom: 1px solid #141414;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 10px;
  padding: 6px 12px;
  width: 100%;
}

/* Countries list background color */
.PhoneNumberField_phoneInputDropdown__3nFCg li:hover {
  background-color: #DE684F !important;
}

.PhoneNumberField_phoneInputDropdown__3nFCg li:nth-child(1):hover {
  background-color: #272a2c !important;
  cursor: text;
}

.PhoneNumberField_phoneInputDropdown__3nFCg .PhoneNumberField_dropdown-menu__e65Hv .PhoneNumberField_dropdown-item__SYN6b:hover {
  background-color: #DE684F !important;
}

.PhoneNumberField_phoneInputSearch__2OvUY input {
  color: white !important;
  width: 195px !important;
  height: 30px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  padding-left: 10px !important;
}

.PhoneNumberField_phoneInput__1pjAb .PhoneNumberField_search-box__1ZzHy input {
  color: white !important;
}

.GuestModalStepper_root__3LT3n {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.GuestModalStepper_step_root__2VhfQ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
  color: #1D1E20;
}

.GuestModalStepper_curent_step__2xmn3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #EFF0F6;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  transition: background-color 0.2s, color 0.2s;
}

.GuestModalStepper_line_step__2QxDN {
  position: relative;
  width: 108px;
  height: 6px;
  border-radius: 40px;
  background-color: #EFF0F6;
  overflow: hidden;
}

.GuestModalStepper_line_step__2QxDN::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #DE684F;
  transition: width 0.2s;
}

.GuestModalStepper_line_step_fill__OtRF_::before {
  width: 50%;
}

.GuestModalStepper_line_step_fill_whole__2dKnQ::before {
  width: 100%;
}

.GuestModalStepper_curent_step_fill__1Zdxo {
  background-color: #DE684F;
  color: #FFFFFF;
}

.GuestModalStepper_curent_step_fill_whole__3zpuV {
  background-color: #DE684F;
  color: #FFFFFF;
}
.StreamInfo_container__3nZSE {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 21px;
    -webkit-column-gap: 21px;
            column-gap: 21px;
    margin-top: 15px;
    padding-bottom: 15px;
}

.MultiSelectField_input__2C-tc {
    width: 244px;
    height: 50px !important;
    cursor: pointer;
}

.MultiSelectField_dropdown__DcSsb {
    background-color: #272a2c;
    border-radius: 0 0 5px 5px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin-top: -3px;
}

.MultiSelectField_dropdownItem__1HSOS {
    padding: 6px 32px 6px 12px;
    cursor: pointer;
}

.ExportConfig_container__1PCzm {
    width: 100%;
    margin-top: 54px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.ExportConfig_wrapper__GFWKQ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-row-gap: 9.55px;
    row-gap: 9.55px;
}

.ExportConfig_option__oHOQc {
    width: 130px;
    height: 78px; /* old values was: 43px (for showing more buttons include AMS) */
    background: #181818;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 6px;
    border: 0.5px solid #DE684F;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.ExportConfig_optionText__33TlQ {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
}

.ExportConfig_mainContent__1kmZl {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 453px;
    height: 253px;
    border-radius: 10px;
    border: 1.5px solid #DE684F;
    background: #181818;
    padding: 0 18px;
}

.ExportConfig_mainContentText__37SoK {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: center;
}

.ExportConfig_active__3gdFh {
    background: #DE684F;
}

.CustomRTMPForm_mainContext__8kaYt {
    width: 453px;
    height: 253px;
    border-radius: 10px;
    border: 1.5px solid #DE684F;
    background: #181818;
    padding: 43px 22px 56px 23px;
}

.CustomRTMPForm_input__ZzRAN {
    width: 408px;
    height: 50px !important;
}


.TextFieldForm_input__2sAqe {
    width: 244px;
    height: 50px !important;
}

.TextFieldForm_inputBlack__3YRIC {
    height: 50px !important;
    background: #1D1E20 !important;
}

.TextFieldForm_inputBlack__3YRIC:focus {
    background: #1D1E20 !important;
}

.TextFieldForm_smallHeight__1-iwi {
    height: 40px !important;
}

.TextFieldForm_error__2m599 {
    color: #ff5f5f;
    font-size: 13px;
}

.SRTCallerForm_mainContext__Qc0Wj {
    width: 453px;
    height: 253px;
    border-radius: 10px;
    border: 1.5px solid #DE684F;
    background: #181818;
    padding: 20px 22px 12px 23px;
}

.SRTCallerForm_input__2VmTa {
    width: 408px;
    height: 50px !important;
}

.SRTCallerForm_inputSmall__2r4A6 {
    width: 200px;
    height: 50px !important;
}

.InviteGuest_container__SQWSj {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.InviteGuest_wrapper__3QgIO {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.InviteGuest_img__17xpZ {
    width: 99px;
    height: 90px;
    margin-bottom: 11px;
}

.InviteGuest_linkImg__s6232 {
    width: 50px;
    height: 50px;
}

.InviteGuest_title__289JD {
    width: 596px;
    font-family: DM Sans;
    font-size: 34px;
    font-weight: 700;
    line-height: 46px;
    text-align: center;
    color: #DE684F;
}

.InviteGuest_paragraph__3I9EJ {
    width: 601px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #D9DBE9;
    margin-bottom: 5px;
}

.InviteGuest_span__WXdsF {
    width: 596px;
    font-family: Roboto;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    color: #DE684F;
    margin-bottom: 30px;
}

.InviteGuest_shareLinks__3mS6L {
    width: 362px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.InviteGuest_icons__22aXq {
    border-top: none !important;
}

.InviteGuest_buttonLink__ArtZI {
    background-color: transparent;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
}
.sliding-panel {
  box-shadow: 0 -4px 4px -4px #a5afb9;
  background-color: #181818 !important;
  width: 99.8vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

.sliding-panel__hide {
  -webkit-transform: translateY(92vh);
          transform: translateY(92vh);
}

.sliding-panel__show {
  -webkit-transform: translateY(42vh);
          transform: translateY(42vh);
  overflow: hidden;
}

.sliding-panel__show_simple {
  -webkit-transform: translateY(50vh);
          transform: translateY(50vh);
  overflow: hidden;
}

.layout-block-center,
.layout-block-left,
.layout-block-right {
  background-color: #181818 !important;
}

.layout-block-center {
  width: 100%;
}

.control-panel {
  height: 8vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.dashboard {
  margin: 1vh
}

.danger-submit-btn,
.danger-submit-btn:active .danger-submit-btn:hover {
  border-color: #DE684F !important;
  background-color: #DE684F !important;
  font-weight: 500;
}

.danger-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi-viewer {
	height: 43vh;
	overflow-y: scroll;
	overflow-x: hidden;
	color: #f4efe9;
}

/* remove scroll bar from window */
.mviewer-scrollbar::-webkit-scrollbar{
	display: none !important;
	/* background-color: #373737 !important; 
    overflow: auto !important;
    height: auto !important;
    width: 20px; */ 
}

/* custom scroller for multiviewer */
/* .mviewer-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.mviewer-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.mviewer-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.mviewer-scrollbar::-webkit-resizer {background-color: #666;} */

.multi-viewer-spinner {
	height: 37vh;
}

.broadcast-details-spinner {
	height: 20vh;
}

.outputs-list-spinner {
	height: 20vh;
}

.settings-control-spinner {
	height: 30vh;
}
.devices-info-spinner {
	height: 35vh;
}

.viewer {
	-webkit-flex-direction: column;
	        flex-direction: column;
    display: -webkit-flex;
    display: flex;
	margin-left: 0.5vw;
	margin-right: 0.5vw;
	margin-bottom: 1vh !important;
	background-color: #202020;
	/* border: 1px solid #373737; */
	border-radius: 5px !important;
	box-shadow: 0 4px 10px 1px rgba(0, 0, 1, 0.1), 0 0px 15px 5px rgba(0, 0, 0, 0.12);

}

/* .drag-and-drop-container {
	
} */

.broadcast-info {
	position: absolute;
	/* top: 65%; */
	/* left: 50%; */
	width: 250px;
	-webkit-transform: translateY(-120%);
	        transform: translateY(-120%);
	color: #f2f2f2;
	text-shadow: 0 0 5px black;
}

.broadcast-info > span {
	display: inline-block;
	width: 50%;
	text-align: center;
}

.broadcast-info-extended {
	position: absolute;
	/* top: 65%; */
	/* left: 50%; */
	width: 350px;
	-webkit-transform: translateY(-120%);
	        transform: translateY(-120%);
	color: #f2f2f2;
	text-shadow: 0 0 5px black;
}

.broadcast-info-extended > span {
	display: inline-block;
	width: 50%;
	text-align: center;
}

.status-icon {
  position: relative;
  /* padding-right: 10px; */
  height: 3.2vh;
}

.primary-name-edit-icon,
.output-settings-icon,
.guid-copy-icon {
  cursor: pointer;
}

.info-header,
.info-header .material-icons {
  font-size: 1rem;
  /* padding-left: 0.5vh; */
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  /* font-weight: 450; */
  color: #f4efe9 !important;
}

.shareIcon {
  border-top: none !important;
  margin: 0 0 4px 5px;
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.img {
  width: 99px;
  height: 90px;
  margin-bottom: 11px;
}

.linkImg {
  width: 50px;
  height: 50px;
}

.title {
  width: 596px;
  font-family: DM Sans;
  font-size: 34px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  color: #DE684F;
}

.paragraph {
  width: 601px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #D9DBE9;
  margin-bottom: 5px;
}

.span {
  width: 596px;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  color: #DE684F;
  margin-bottom: 30px;
}

.shareLinks {
  width: 362px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.icons {
  border-top: none !important;
}

.buttonLink {
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.material-icons:hover {color: #f4efe9 !important;}
.material-icons:active {color: #f4efe9 !important;}

.alarm-icon-container-absolute {
  position: absolute;
  padding: 3px;
  right: 0px;
  /* padding: 5px; */
}

.alarm-icon-container-relative {
  /* position: relative; */
  padding: 3px;
  right: 0px;
  /* padding: 5px; */
}

.alarm-icon {
  padding-right: 0.3rem;
}

.alarm-icon-tooltip-down .tooltip-message {
  top: 120%;
}

.alarm-icon-tooltip-up .tooltip-message {
  bottom: 125%;
}

.tooltip-message {
  visibility: hidden;
  position: absolute;
  left: -315%;
  z-index: 3;
  width: 203px;
  border: 1.5px solid #f4efe9;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  background-color: #de684f;
  color: #f4efe9;
}

/* -------------- Tooltip arrow styles start -------------- */
.alarm-icon-tooltip-down .tooltip-message::after {
  content: ' ';
  position: absolute;
  bottom: 95%;
  margin-left: 70px;
  border-width: 0 8px 12px 8px;
  border-style: solid;
  border-color: transparent transparent #de684f transparent;
}

.alarm-icon-tooltip-down .tooltip-message::before {
  content: ' ';
  position: absolute;
  bottom: 102%;
  right: 14px;
  margin-left: -5px;
  border-width: 0 8px 12px 8px;
  border-style: solid;
  border-color: transparent transparent #f4efe9 transparent;
}

.alarm-icon-tooltip-up .tooltip-message::after {
  top: 100%;
  border-color: #f4efe9 transparent transparent transparent;
  border-width: 7px !important;
}

.tooltip-message::after {
  position: absolute;
  left: 50%;
  margin-left: -2.5%;
  content: ' ';
  /* border-width: 0.5rem; */
  border-style: solid;
}
/* -------------- Tooltip arrow styles end -------------- */

.show-tooltip {
  visibility: visible;
}

.thumbnail-img-input {
	/* padding: 10px; */
	--thumbnailWidth: 250px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background: #272727 url("/images/videocam-white-24dp.svg") no-repeat center;
	background-size: 100px auto;
}

.thumbnail-img-input-extended {
	/* padding: 10px; */
	--thumbnailWidth: 350px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background: #272727 url("/images/videocam-white-24dp.svg") no-repeat center;
	background-size: 100px auto;
}

.thumbnail-img-output {
	/* padding: 10px; */
	--thumbnailWidth: 250px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16.1);
	/* background: #2e2e2e url("/images/video_label-white-24dp.svg") no-repeat center; */
	/* background-size: 100px auto; */
    background: #2e2e2e url("/images/screen_saver.gif") no-repeat center;	
	background-size: 95% auto;
}

/* .mini-preview{
	position: relative;
} */

.critical-issue-container {
	font-size: 1.8em;
	font-weight: 700;
}

.critical-issue-container-icon {
	font-size: 2.7em;
}

.outputs-list {
  background-color: #181818 !important;
  color: #f4efe9;
  /* height: 50vh; */
  border-top: 1px solid #292929;
  overflow-y: scroll;
}

.outputs-scrollbar::-webkit-scrollbar{
  display: none !important;
   /*background-color: #373737 !important;  
    overflow: auto !important;
    height: auto !important;
    width: 20px;*/ 
}

/* custom scroller for outputs */
/* .outputs-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.outputs-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.outputs-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.outputs-scrollbar::-webkit-resizer {background-color: #666;} */
.output {
	display: none;
	margin-left: 0.5vw;
	margin-right: 0.5vw;
	margin-bottom: 1vh !important;
	background-color: #202020;
	border: 1px solid #181818;
	box-shadow: 0 4px 10px 1px rgba(0, 0, 1, 0.1), 0 0px 15px 5px rgba(0, 0, 0, 0.12);

}

.critical-alarm-thumbnail {
	--thumbnailWidth: 203px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background-color: #202020;
	cursor: pointer;
}

.critical-alarm-icon {
	font-size: 7em;
}

.warning-alarm-icon-container {
	bottom: 20%;
	position: absolute;
	left: 85%;
	text-shadow: 0 0 5px black;
}

.broadcast-details {
	padding-top: 15px;
	background-color: #181818;
}

.control-buttons-custom-style {
	background: #f2f2f2;
}

.details-container {
	width: 76vw;
}

.alarms-container {
	width: 54vh;
	padding-left: 1rem;
}


.broadcast-settings ul,
.broadcast-settings .data-area {
	margin-left: 0.5vw;
	
}

#settings-сontrol {
	width: 9.6vw;
}

#rtmp,
#output-association {
	width: 6vw;

}

#outputs-save-btn {
	margin-top: 3vh;
	width: 8vw;
}

.broadcast-settings-padding{
	padding-right: 0px !important;
}
.dark-mode{
  background-color: #181818 !important;

}

.tab-pointer {
  cursor: pointer;
  background-color: #181818;
}

.btn-icon {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.icon-pending-alarms {
  color: #dc3545;
}

.icon-pending-alerts {
  color: #ffc107 !important;
}

.is-exist-export {
  height: 8px;
  width: 8px;
  background-color: #10fc27;
  border-radius: 100%;
  position: absolute;
  margin-top: -1px;
  margin-left: -1px;
}

/* custom scroller for records scrollbar */
.records-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-button {
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-track {
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-track-piece {
  background-color: #202020;
}
.records-scrollbar::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
.records-scrollbar::-webkit-scrollbar-corner {
  background-color: #646464;
}
.records-scrollbar::-webkit-resizer {
  background-color: #666;
}


.card-link {
	background-color: #181818;
	color: #f4efe9;
	display: inline-block;
	width: 100%;
	height: 100%;
}

#settings-apply-btn,
#settings-revert-btn {
	/* width: 6vw; */
}

.settings-control {
	display:block; 
	overflow: hidden;
	border: none !important;
}

.icon-size {
	vertical-align: -7%;
	padding-right: 2px;
	font-size: small;
}

.drawer-text {
	margin: 0 auto;
	color:#f4efe9;
}

.arrow-icon {
	color: #f4efe9;
}


.form-inline {
	margin-bottom: 0.3rem;
	margin-left: 1vw;
}

.select-input label {
	font-size: small;
	width: 25%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

select {
	background-color: #272a2c !important;
	color: white !important;
	border: none !important;
}

.select-input select {
	font-size: small;
	width: 67% !important;
	max-width: 67% !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.output-association-select-input select {
	height: 2.3rem;
}

.alerts-filter-select-input {
	margin-left: 0;
}
.alerts-filter-select-input label {
	width: 15%;
}
.alerts-filter-select-input select {
	width: 85% !important;
}

.alert-delete {
	margin-left: 3px;
	margin-right: 3px;
}


.carousel-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.carousel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.carousel-arrow-icon {
  font-size: 30px;
}

.carousel-dots-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.carousel-dot-icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}

.carousel-dot-active-icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #de684f;
  transition: 0.5s;
}

.carousel-dot-mr {
  margin-right: 1rem;
}

.carousel-dot-mr:last-child {
  margin-right: 0px;
}

.export-list-item-container {
  background-color: #181818 !important;
  padding-top: 2px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-width: 10rem;
  font-size: 0.8rem;
  height: 100%;
  overflow-y: auto;
}

.export-list-item-container::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background-color: #373737;
}

.export-list-item-container::-webkit-scrollbar-button {
  background-color: #373737;
}

.export-list-item-container::-webkit-scrollbar-track {
  background-color: #373737;
}

.export-list-item-container::-webkit-scrollbar-track-piece {
  background-color: #373737;
}

.export-list-item-container::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}

.export-list-item-container::-webkit-scrollbar-corner {
  background-color: #646464;
}

.export-list-item-container::-webkit-resizer {
  background-color: #666;
}

.options-container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; /* was space-between */
  color: #f4efe9 !important;
}

.export-container span {
  cursor: pointer;
  /* font-weight: 600;
	font-size: 1.5rem; */
}

.export-container {
  height: 100%;
}

.list-item-container {
  word-wrap: break-word;
}

.export-heading {
  color: #ffffff !important;
  padding-top: 3px !important;
  padding-bottom: 2px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.export-heading-text-container {
  display: -webkit-flex;
  display: flex;
}

.export-label-heading {
  color: #ffffff !important;
  padding-bottom: 2px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.edit-label-input-style {
  margin-left: 0px !important;
  padding-top: 10px !important;
}

.export-link {
  padding-left: 3px !important;
  color: #f3f3f3 !important;
  padding-bottom: 5px !important;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
}

/* .export-container span:hover {
	cursor: pointer;
	color: #b1dffd;
} */

.hide {
  display: none;
}

/* toggle button css */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #de684f;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #de684f;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-check svg {
  border-top: none !important;
}

.react-toggle-track-check,
.react-toggle-track-x svg {
  border-top: none !important;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  /* border: 1px solid #de684f; */
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #de684f;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #9e9e9e;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #9e9e9e;
}

.delete-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.delete-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.edit-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.edit-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.copy-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.copy-btn-input-fields {
  color: #747474;
  vertical-align: middle !important;
}

.copy-btn-input-fields:hover {
  color: #d3d3d3 !important;
  vertical-align: middle !important;
}

.copy-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.copy-btn-input {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.7;
  margin-top: -1.7%;
  color: rgb(105, 105, 105) !important;
  font-size: 20px;
}

.copy-btn-input:hover {
  color: #d3d3d3 !important;
}

.copy-btn-input-disabled {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
  margin-top: -1.7%;
  color: rgb(105, 105, 105) !important;
  font-size: 20px;
}

.copy-btn-input-disabled:hover {
  color: rgb(105, 105, 105) !important;
}

.playlist_remove {
  display: -webkit-flex;
  display: flex;
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
  font-size: 30px;
}

.label-delete-btn {
  font-size: 20px;
}

.label-edit-btn {
  font-size: 20px;
}

.label-cancel-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-cancel-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-cancel-btn:hover {
  color: #ed6243 !important;
}

.label-save-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-save-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-save-btn:hover {
  color: #ed6243 !important;
}

.form-padd {
  display: grid;
}

.form-padd label {
  margin-top: -1.7%;
  padding-top: 0px !important;
  display: inline-block !important;
}

.form-padd input {
  margin-top: -1.7%;
  padding-top: 0px !important;
  display: inline-block !important;
}

.container-padd {
  /* margin-top: 4%; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container-padd::-webkit-scrollbar {
  /* chrome based */
  width: 0px;
  background: transparent;
}

.arrow-btn {
  position: static !important;
  position: initial !important;
  vertical-align: -15% !important;
}

.form-check-inline {
  display: inline-block !important;
  margin: 2px !important;
}

.button-srt {
  cursor: pointer !important;
  background-color: #c0c0c0 !important;
  color: #3e423f;
}

.button-rtmp {
  cursor: pointer !important;
  background-color: white !important;
}

.button-omniplayer {
  cursor: pointer !important;
  background-color: #de684f !important;
}

.label-container {
  margin: 0px !important;
  padding: 0px !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.label-container-text {
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
}

.label-static-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
  font-size: 16px;
  padding-left: 2% !important;
  font-weight: 500 !important;
}

.export_btns_padd {
  padding-left: 2px !important;
}

.copy_btn_pad {
  margin-left: 10px !important;
}

.label-icon-size {
  font-size: 24px !important;
  padding-left: 1% !important;
}

hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      #d8d8d865,
      #adadad,
      #d8d8d865,
      rgba(255, 255, 255, 0));
  border: 0;
  height: 1px;
}

.hr-label {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      #d8d8d865,
      #adadad,
      #d8d8d865,
      rgba(255, 255, 255, 0));
  border: 0;
  height: 1px;
}

.label-buttons-paddings {
  padding-right: 20px !important;
}

.label-txt-dsc {
  color: #f3f3f3 !important;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
  margin-left: -2px !important;
  text-transform: capitalize;
}

.export-label-btns {
  vertical-align: sub !important;
  display: inline-block !important;
  font-size: 22px !important;
  background-color: #646464;
  color: #ffffff !important;
  border: none !important;
  border-radius: 5px !important;
  background: transparent;
}

.buttons-edit-label {
  padding-top: 8px !important;
  margin-bottom: 0px !important;
}

.edit-label-input-style {
  display: -webkit-flex !important;
  display: flex !important;
  padding-left: 0px !important;
}

.edit-label-buttons-style {
  display: -webkit-flex !important;
  display: flex !important;
  padding-left: 5px !important;
}

.edit-text-input {
  margin-left: -5px !important;
  width: 262px !important;
}

.export-heading-container {
  display: -webkit-flex;
  display: flex;
}

#host.form-control {
  font-size: 100% !important;
}

#port.form-control {
  font-size: 100% !important;
}

#key.form-control {
  font-size: 100% !important;
}

.export-list-option {
  height: 100%;
  overflow: hidden;
}

/* #create-export {
  width: 100% !important;
  transform: translateX(5%) !important;
} */

.export-list-option-item {
  width: 250px !important;
  height: 48px;
  background: #ffffff;
  border-radius: 10px;
  text-transform: uppercase;
}

.show-export-list {
  position: static;
  /* bottom: 0; */
  margin-top: 0px !important;
  width: 250px; /* was 100% */
  height: 48px; /* was 45px */
  background: #de684f;
  color: white;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  border-radius: 10px; /* was 0 */
  /* border-top: 0.75px solid #3e403f; */
}

.show-export-list:hover {
  color: white;
}

.show-export-list-text {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 0.5rem;
}

.show-export-list-text span {
  margin-right: 0.5rem;
}

.show-export-list-text-bold {
  font-weight: 600 !important;
  letter-spacing: .03rem;
}

.export-list-item-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.list-bottom-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #202020 !important;
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}

.list-bottom-buttons-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.list-bottom-buttons {
  width: 100px;
  height: 35px;
  margin: 0.5rem !important;
  background-color: #de684f;
  color: white;
  border-radius: 5px;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.list-bottom-buttons:hover {
  color: white;
}

.list-bottom-buttons-dark {
  width: 100px;
  height: 35px;
  margin: 0.5rem !important;
  background-color: #181818;
  color: white;
  border-radius: 5px;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border: 1px solid #de684f;
}

.list-bottom-buttons-dark:hover {
  color: white;
}

.list-bottom-buttons-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.list-bottom-buttons-icons {
  font-size: large;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.export-image-container {
  margin-right: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.export-image-container img {
  width: 22px !important;
  height: 22px !important;
  border-radius: 5px !important;
}

.export-heading b {
  text-transform: uppercase;
}

.label-buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: calc(1.5em + 0.5rem + 2px);
}

.copy-btn-input-export {
  margin-left: 0px !important;
  line-height: 1.7;
  font-size: 20px;
}

.btn-input-export-container {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
}

.export-list-option-light-active {
  width: 14px;
  height: 14px;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-left: auto;
  /* background-color: #10fc27; */
  border-radius: 100%;
  background: radial-gradient(circle at 50% 50%, #10fc27, #17bb28);
}

.export-list-option-light-disable {
  width: 14px;
  height: 14px;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-left: auto;
  /* background-color: #ed6243; */
  border-radius: 100%;
  background: radial-gradient(circle at 50% 50%, #ed6243, #a8412a);
}

.export-list-option-text-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: relative;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 22px;
}

.export-list-option-text {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #000000 !important;
}

.text-omniplayer {
  color: white !important;
}

.mi-1 {
  font-size: 20px;
}

.react-toggle-track {
  width: 43px;
  height: 22px;
}

.react-toggle--checked .react-toggle-thumb {
  left: 24px;
}

.react-toggle-thumb {
  width: 20px;
  height: 20px;
}

.react-toggle-track-check svg {
  margin-left: -0.15rem;
}

.react-toggle-track-x svg {
  margin-left: 0.15rem;
}

.playlist_remove_icon {
  font-size: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.playlist_remove_button {
  width: 50px;
  padding: 2px;
}

.my-tooltip {
  position: relative;
  display: inline-block;
}

.my-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background: #252525;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;
  width: 150px;
  bottom: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  z-index: 1;
}

.my-tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text {
  display: inline-block;
  position: relative;
  border: 2px solid #4d4d4d;
  text-decoration: none;
  border-radius: 2px;
  padding: 20px;
  margin-top: 50px;
}

.my-tooltip .tooltip-text::after {
  content: ' ';
  position: absolute;
  top: 95%;
  right: 80%;
  margin-left: -5px;
  border-width: 12px 8px 0 8px;
  border-style: solid;
  border-color: #252525 transparent transparent transparent;
}

.my-tooltip .tooltip-text::before {
  content: ' ';
  position: absolute;
  top: 110%;
  right: 80%;
  margin-left: -5px;
  border-width: 12px 8px 0 8px;
  border-style: solid;
  border-color: #4d4d4d transparent transparent transparent;
}

.copy-embed-icon {
  display: -webkit-flex;
  display: flex;
}

.play-video-icon {
  display: -webkit-flex;
  display: flex;
}

.SRTCaller-input-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.SRTCaller-input {
  width: 50%;
}

.SRTCaller-input:nth-child(1) {
  padding-right: 4px;
}

.SRTCaller-input:nth-child(2) {
  padding-left: 4px;
}

.input-custom {
  padding-right: 2rem !important;
}

.input-custom-key {
  padding-right: 3.5rem !important;
}

.remove_cursor {
  cursor: not-allowed !important;
}

@media screen and (min-width: 1750px) {
  .list-bottom-buttons-container {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-right: 66px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media screen and (min-height: 1200px) { /* was 1000px */
  .show-export-list {
    position: absolute;
  }

  .options-container {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .container-padd {
    margin-top: 0;
  }
}

.records {
    height: 34vh;
}

.record-info {
    width: 100%;
}

/* .record-text {
    /* margin-bottom: 0.5rem; 
} */

.records-control-spinner {
    height: 34vh;
}

.arc-wrapper {
    position: relative;
}

.arc {
    position: absolute;
    border-radius: 100%;
    width: 2.4em;
    height: 2.4em;
}

.arc_start {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border: 4px solid;
}

.percentage {
    text-align: center;
    width: 2.4rem;
    height: 2.4rem;
    padding-top: 0.65rem;
    font-size: 0.7rem;
    color: #f4efe9;
}


.record-title {
    font-weight: bold;
    font-size: 1.2em;
}

.record-container {
    border: 1px solid #353638;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin: 0.3rem;
    background-color: #181818;
}

#delete-record-btn, #download-record-btn, #pause-record,
#record-uploaded, #play-record {
    height: 4.5vh;
    margin-right: 1vh;
}

#record-uploaded {
    padding: 0.7rem;
}

.progress-wrapper {
    width: 60%;
    height: 2.4rem;
}

.action-wrapper {
    width: 40%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.row-wrapper {
    width: 100%;
}
.chart-buttons-section {
    position: absolute;
    left: calc(50% - 100px);
}

.chart-button-container {
    padding: 5px;
    border-radius: 4px;
    background-color: #2a2a2a;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.chart-button-container:hover {
    background-color: #3a3a3a;
}

.chart-providers {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.no-data-message {
    -webkit-transform: translateX(150px);
            transform: translateX(150px);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center !important;
            justify-content: center !important;
    -webkit-align-items: center;
            align-items: center;
    height: 310px;
    font-size: 16px;
    color: #f4efe9;
    background-color: #181818;
}

.chart-container {
    width: 100%;
    max-width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 310px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 0px;
}

.chart-and-legend {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.custom-legend.single-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.custom-legend {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 5px 15px;
    gap: 5px 15px;
    width: 100%;
    max-width: 40%;
    color: #f4efe9;
    position: absolute;
    top: 38%;
    left: 52%;
    max-height: 200px;
    padding-right: 15px;
}

.legend-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 13px;
    color: #f4efe9;
    margin-bottom: 5px;
    white-space: nowrap;
}

.legend-square {
    width: 16px;
    height: 16px;
    margin-right: 10px !important;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.legend-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: calc(100% - 50px);
}

.legend-bitrate {
    white-space: nowrap;
    margin-left: 4px;
}

.legend-marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}

.measured-bitrate {
    color: #f4efe9;
    text-wrap: nowrap !important;
}

svg>g>g:last-child {
    pointer-events: none;
}

/* svg {
    border-top: solid 1px #454d55 !important;
} */

.chart-buttons-section {
  position: absolute;
  left: calc(50% - 100px);
  padding-top: 0px !important;
}

.chart-button-container {
  padding: 3px;
  border-radius: 4px;
  background-color: #2a2a2a;
  transition: background-color 0.3s ease;
  margin-right: 5px;
  font-size: 14px;
}

.chart-button-container:last-child {
  margin-right: 0;
}

.chart-button-container:hover {
  background-color: #3a3a3a;
}

.chart-canvas-border {
  border-top: solid 1px #454d55 !important;
}

.chart-legends-container {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  gap: 10px;
  padding: 5px 10px 0;
  margin-top: 30px;
  font-size: 13px;
  -webkit-justify-content: start;
          justify-content: start;
  text-align: left;
}

.chart-legends-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.legend-square {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-color: #ffffff;
  border-radius: 3px;
}

@media (min-width: 1000px) and (max-width: 1400px) {
  #chart-canvas {
    max-height: 175px;
  }
}

@media (min-width: 1700px) {
  #chart-canvas {
    max-height: 280px;
  }
}

.devices-info {
	height: 100%;
	overflow-y: scroll;
}

.device-name-column {
	font-size: small;
	width: 14%;
}

.battery-column {
	font-size: small;
	width: 10%;
}
.temperature-column {
	font-size: small;
	width: 0.1%;
}
.provider-column {
	font-size: small;
	width: 10%;
}
.network-type-column {
	font-size: small;
	width: 4%;
}
.signal-strength-column {
	font-size: small;
	width: 10%;
}

.table th,
.table td {
	padding: 0.6rem; 
	border: 0.75px solid #353638;
}
.lower-rows th,
.lower-rows td {
	padding: 0.3rem 0.6rem;
}


.devices-table-scroll {
	overflow-y: scroll;
}

[class*="signal-bars-"] {
	opacity: 0;
}

.signal-bars-1 {
	line-height: 0.3rem;
}

.signal-bars-2 {
	line-height: 0.8rem;
}

.signal-bars-3 {
	line-height: 1.3rem;
}

.signal-bars-4 {
	line-height: 1.8rem;
}

.progress { 
	height: 10px;
}

.txt-color{
	color: #f4efe9;
}
.tab-pointer{
    /* border-bottom: 1px solid #3f4347 !important; */
    border: none !important;
    background-color: #272727;
    color: #f4efe9;
    cursor: pointer;
}

.none_active_tab{
    /* border: none !important; */
    border-bottom: solid 1px #454d55 !important;
    background-color: #181818 !important;
    color: #f4efe9 !important;
}

.none_active_tab:hover{border-bottom: solid 1px #454d55 !important;}
.none_active_tab:active{border-bottom: solid 1px #454d55 !important;}

.nav-item{
    border: none !important;
    background-color: #181818 !important;
    /* background-color: #252525 !important; */
    color: #f4efe9 !important; 
}

/* .nav-item :hover{
    border: none !important;
} */

.bitrate-chart-data-container {
    width: 100%;
    max-width: none;
    padding-top: 0% !important;
}

.pie-data-container {
    width: 100%;
    max-width: none;
    padding-top: 4% !important;
    padding-right: 30% !important;
    border-top: solid 1px #454d55 !important;
}

.nav-item .active{
    border: none !important;
    /* background-color: #373737 !important; */
    color: #f4efe9 !important;
}

.tab-activate .active{
    border: none !important;
    background-color: #202020 !important;
    color: #f4efe9 !important;
}

/* .tab-activate :hover{
} */

.alerts-bg{
    background-color: #181818 !important;
    color: #f4efe9;
    overflow: hidden !important;
}

.bitrate-chart-and-devices-info{padding-right: 14px !important;}

/* remove scroll bar from devices info table */
.devices-info-scrollbar ::-webkit-scrollbar{
    display: none !important;
    /* background-color: #373737 !important;  
    overflow: auto !important;
    height: auto !important;
    width: 15px; */
}

/* custom scroller for logs devices info scrollbar */
/* .devices-info-scrollbar ::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-button {background-color: #373737; }
.devices-info-scrollbar ::-webkit-scrollbar-track {background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-track-piece {background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.devices-info-scrollbar ::-webkit-scrollbar-corner { background-color: #646464;}
.devices-info-scrollbar ::-webkit-resizer {background-color: #666;} */

.alarms-and-alerts .data-area {
	height: 40vh;
	padding: 0.3rem;
}

.alarms-and-alerts-padding {
	padding-left: 0px !important;
}
.alarms-section ul {
	padding-left: 0;
	list-style-type: none;
}

.close, .ml-2{
	color:#ffffff !important;
}

.alert-size{
    font-size: 1rem;
}

.alert-text{
    font-size: 0.8rem;
}
.logs-section {
	position: relative;
	-webkit-flex-direction: column;
	        flex-direction: column;
	display: -webkit-flex;
	display: flex;
	height: 34vh;
	width: 100%;
	overflow-y: scroll;
	border: 0.75px solid #3e403f;
	border-radius: 0.35rem;
}

.logs-section ul {
	margin-top: 22px;
	/* margin-block-end: 5px; */
	width: 100%;
	position: absolute;
	padding-left: 0.4vw;
	list-style-type: none;
}

.clear-btn {
	color: #f4efe9 !important;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: inline-block;
}

.clear-btn:focus {
	outline:none;
	box-shadow: none;
  }

  /* .clear-btn:hover {
	color: #b1dffd;
  } */

/* custom scroller for logs scrollbar */
.logs-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #202020;}
.logs-scrollbar::-webkit-scrollbar-button {background-color: #202020; }
.logs-scrollbar::-webkit-scrollbar-track {background-color: #202020;}
.logs-scrollbar::-webkit-scrollbar-track-piece {background-color: #202020;}
.logs-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.logs-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.logs-scrollbar::-webkit-resizer {background-color: #666;}

.displayed-name-output-input-container {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 0px !important;
	padding-top: 10px;
	height: 50px !important;
	-webkit-justify-content: left !important;
	        justify-content: left !important;
}

.displayed-name-output-input-container label {
	margin-bottom: 0px !important;
	margin-right: 5px;
	padding-top: 5px;
	white-space: nowrap;
}

.displayed-name-output-input-container div {
	display: inline-block;
	margin-top: 12px;
	width: 95% !important;
}

#displayed-name-input {
	display: inline-block;
	width: 100%;
	margin-left: 7px;
	text-transform: capitalize !important;
}

.modal-footer {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.dialog-button {
  min-width: 100px;
  background-color: #de684f !important;
  border-color: #1D1E20 !important;
  color: white;
}

.dialog-button-edit-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.dialog-button-edit {
  width: 100%;
}

.header-container {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.modal-footer {
  text-align: center !important;
  display: inline-block !important;
}

.edit-container {
  margin-bottom: 20px;
}

.dialog-pad-top {
  padding-top: 25px !important;
  padding-bottom: 15px !important;
}

.dialog-pad-bottom {
  padding-top: 15px !important;
  padding-bottom: 25px !important;
}

.modal-title {
  text-align: center !important;
}

#first-modal {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.modal-footer-fields-container {
  margin-right: 0px !important;
  text-align: start;
}

.modal-footer-buttons-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: 0px !important;
}

.modal-footer-buttons-container button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.modal-field-icon-star {
  margin-left: 2px;
  margin-bottom: -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-end;
          align-content: flex-end;
  font-size: 30px;
  color: #de684f;
}

.modal-field-label {
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.modal-field-label-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.modal-field-label-required {
  margin-top: -10px;
}

.cap-letter {
  text-transform: capitalize !important;
}

.modal-line-height {
  line-height: 2 !important;
}

.modal-body {
  white-space: nowrap !important;
}

.modal input {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}
.modal input:valid {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}
/* Autofill styles */
.modal input:-webkit-autofill,
.modal input:-webkit-autofill:hover,
.modal input:-webkit-autofill:focus,
.modal input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #272a2c inset !important;
  -webkit-text-fill-color: #f4efe9 !important;
}
/* Hover and focus styles */
.modal input:hover,
.modal input:active,
.modal input:focus {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.modal-content-video {
  height: 450px;
  width: 770px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #3e403f;
}

.modal-dialog-video {
  height: 450px;
  width: 770px;
  max-width: 770px;
  background: transparent !important;
  border: none !important;
}

.close-modal-btn-default {
  position: absolute;
  height: 26px;
  top: -10px;
  right: 0;
  bottom: 0;
  background: transparent;
  color: white;
  border: none;
  font-size: 26px !important;
}

.modal-media-icons-container {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.modal-media-icons-container svg {
  border-top: none !important;
}

.livePlayer-text-container {
  margin-bottom: 10px;
  width: 770px;
  height: 26px;
  display: -webkit-flex;
  display: flex;
}

.livePlayer-text {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  text-transform: uppercase;
}

.modal_media_container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 45px;
  padding-right: 45px;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal_media_container svg {
  border: none !important;
}

.copy_icon_rounded:hover {
  cursor: pointer !important;
}
.control-buttons button {
	/* padding: 0.5rem; */
}

.control-buttons .col {
	padding-left: 0.1rem;
}

.dropdown-item span {
	margin-left: 3px;
	margin-right: 3px;
	font-size: 15px;
}

.dropdown-menu-no-padding{
	background-color: rgb(15, 15, 15) !important;
	padding: 0;
	z-index: 999;
	width: auto !important;
}

.hr {
	background:transparent;
	border-bottom:2px solid #272A2C;
  }

.broadcast-details-toggle-btn {
    margin: 5px;
}

.center-in-layout {
    position: absolute;
    left: calc(50% - 100px);
}
.content_container {
    padding: 21px 62px 37px;
    width: 745px;
    height: 498px;
    background: #1D1E20 !important;
    border-radius: 25px !important;
    border: none !important;
}

.button_wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 43px;
}

.content_container {
    padding: 21px 62px 37px;
    width: 745px;
    height: 498px;
    background: #1D1E20 !important;
    border-radius: 25px !important;
    border: none !important;
}

.button_wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 43px;
}

#map-container {
	width: 100%;
	height: 89vh;
}

.map-cards-section {
	position: fixed;
	left: 1.3vw;
	top: 12vh;
	z-index: 2;
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
	.map-cards-section {
		top: 23vh;
	}
}

.map-marker-container {
	width: 10rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;

	-webkit-align-items: center;

	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	font-size: 1rem;
	font-weight: bold;
}

.marker-image-container {
	margin-left: 12%;
}

.map-card {
	opacity: 0.95 !important;
	width: 13vw;
	margin-bottom: 1.5vh;
}

.card-map-color{background-color: #373737 !important; 	padding-bottom: 10px !important;}
.card-map-color button{background-color: #de684f !important;border: none !important;}
.card-map-color button:hover{color: #f4efe9 !important;border: none !important; }
.card-map-color button:active{border: none !important;}

.card-link{color: #f4efe9 !important;}

.bg-info-border{background-color: #373737 !important;}
