.records {
    height: 34vh;
}

.record-info {
    width: 100%;
}

/* .record-text {
    /* margin-bottom: 0.5rem; 
} */

.records-control-spinner {
    height: 34vh;
}
