.multi-viewer-spinner {
	height: 37vh;
}

.broadcast-details-spinner {
	height: 20vh;
}

.outputs-list-spinner {
	height: 20vh;
}

.settings-control-spinner {
	height: 30vh;
}
.devices-info-spinner {
	height: 35vh;
}
