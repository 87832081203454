.mainContext {
    width: 453px;
    height: 253px;
    border-radius: 10px;
    border: 1.5px solid #DE684F;
    background: #181818;
    padding: 43px 22px 56px 23px;
}

.input {
    width: 408px;
    height: 50px !important;
}

