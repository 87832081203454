.broadcast-details {
	padding-top: 15px;
	background-color: #181818;
}

.control-buttons-custom-style {
	background: #f2f2f2;
}

.details-container {
	width: 76vw;
}

.alarms-container {
	width: 54vh;
	padding-left: 1rem;
}

