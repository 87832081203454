.map-marker-container {
	width: 10rem;
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
}

.marker-image-container {
	margin-left: 12%;
}
