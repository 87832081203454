.devices-table-scroll {
	overflow-y: scroll;
}

[class*="signal-bars-"] {
	opacity: 0;
}

.signal-bars-1 {
	line-height: 0.3rem;
}

.signal-bars-2 {
	line-height: 0.8rem;
}

.signal-bars-3 {
	line-height: 1.3rem;
}

.signal-bars-4 {
	line-height: 1.8rem;
}

.progress { 
	height: 10px;
}

.txt-color{
	color: #f4efe9;
}