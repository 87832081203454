#map-container {
	width: 100%;
	height: 89vh;
}

.map-cards-section {
	position: fixed;
	left: 1.3vw;
	top: 12vh;
	z-index: 2;
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
	.map-cards-section {
		top: 23vh;
	}
}
