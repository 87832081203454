.displayed-name-output-input-container {
	display: flex;
	align-items: center;
	margin-bottom: 0px !important;
	padding-top: 10px;
	height: 50px !important;
	justify-content: left !important;
}

.displayed-name-output-input-container label {
	margin-bottom: 0px !important;
	margin-right: 5px;
	padding-top: 5px;
	white-space: nowrap;
}

.displayed-name-output-input-container div {
	display: inline-block;
	margin-top: 12px;
	width: 95% !important;
}

#displayed-name-input {
	display: inline-block;
	width: 100%;
	margin-left: 7px;
	text-transform: capitalize !important;
}
