.input {
    width: 244px;
    height: 50px !important;
    cursor: pointer;
}

.dropdown {
    background-color: #272a2c;
    border-radius: 0 0 5px 5px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin-top: -3px;
}

.dropdownItem {
    padding: 6px 32px 6px 12px;
    cursor: pointer;
}
