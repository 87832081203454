.map-card {
	opacity: 0.95 !important;
	width: 13vw;
	margin-bottom: 1.5vh;
}

.card-map-color{background-color: #373737 !important; 	padding-bottom: 10px !important;}
.card-map-color button{background-color: #de684f !important;border: none !important;}
.card-map-color button:hover{color: #f4efe9 !important;border: none !important; }
.card-map-color button:active{border: none !important;}

.card-link{color: #f4efe9 !important;}

.bg-info-border{background-color: #373737 !important;}