
.card-link {
	background-color: #181818;
	color: #f4efe9;
	display: inline-block;
	width: 100%;
	height: 100%;
}

#settings-apply-btn,
#settings-revert-btn {
	/* width: 6vw; */
}

.settings-control {
	display:block; 
	overflow: hidden;
	border: none !important;
}

.icon-size {
	vertical-align: -7%;
	padding-right: 2px;
	font-size: small;
}

.drawer-text {
	margin: 0 auto;
	color:#f4efe9;
}

.arrow-icon {
	color: #f4efe9;
}

