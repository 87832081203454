.chart-buttons-section {
    position: absolute;
    left: calc(50% - 100px);
}

.chart-button-container {
    padding: 5px;
    border-radius: 4px;
    background-color: #2a2a2a;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.chart-button-container:hover {
    background-color: #3a3a3a;
}

.chart-providers {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data-message {
    transform: translateX(150px);
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: 310px;
    font-size: 16px;
    color: #f4efe9;
    background-color: #181818;
}

.chart-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px;
    flex: 1 1 auto;
    padding-right: 0px;
}

.chart-and-legend {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.custom-legend.single-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.custom-legend {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-rows: auto;
    gap: 5px 15px;
    width: 100%;
    max-width: 40%;
    color: #f4efe9;
    position: absolute;
    top: 38%;
    left: 52%;
    max-height: 200px;
    padding-right: 15px;
}

.legend-item {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #f4efe9;
    margin-bottom: 5px;
    white-space: nowrap;
}

.legend-square {
    width: 16px;
    height: 16px;
    margin-right: 10px !important;
    background-color: #ffffff;
    border-radius: 3px;
    flex-shrink: 0;
}

.legend-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: calc(100% - 50px);
}

.legend-bitrate {
    white-space: nowrap;
    margin-left: 4px;
}

.legend-marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}

.measured-bitrate {
    color: #f4efe9;
    text-wrap: nowrap !important;
}

svg>g>g:last-child {
    pointer-events: none;
}

/* svg {
    border-top: solid 1px #454d55 !important;
} */
