@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img {
    width: 99px;
    height: 90px;
    margin-bottom: 11px;
}

.linkImg {
    width: 50px;
    height: 50px;
}

.title {
    width: 596px;
    font-family: DM Sans;
    font-size: 34px;
    font-weight: 700;
    line-height: 46px;
    text-align: center;
    color: #DE684F;
}

.paragraph {
    width: 601px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #D9DBE9;
    margin-bottom: 5px;
}

.span {
    width: 596px;
    font-family: Roboto;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    color: #DE684F;
    margin-bottom: 30px;
}

.shareLinks {
    width: 362px;
    display: flex;
    justify-content: space-between;
}

.icons {
    border-top: none !important;
}

.buttonLink {
    background-color: transparent;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
}