.body {
  white-space: pre-wrap !important;
  font-size: 0.9vw !important;
  font-weight: 500 !important;
  position: relative;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  border: none !important;
}

.get-more-guests-modal {
  width: 550px !important;
}