.form-inline {
	margin-bottom: 0.3rem;
	margin-left: 1vw;
}

.select-input label {
	font-size: small;
	width: 25%;
	display: flex;
	justify-content: flex-start;
}

select {
	background-color: #272a2c !important;
	color: white !important;
	border: none !important;
}

.select-input select {
	font-size: small;
	width: 67% !important;
	max-width: 67% !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.output-association-select-input select {
	height: 2.3rem;
}

.alerts-filter-select-input {
	margin-left: 0;
}
.alerts-filter-select-input label {
	width: 15%;
}
.alerts-filter-select-input select {
	width: 85% !important;
}

.alert-delete {
	margin-left: 3px;
	margin-right: 3px;
}

