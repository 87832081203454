

.record-title {
    font-weight: bold;
    font-size: 1.2em;
}

.record-container {
    border: 1px solid #353638;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin: 0.3rem;
    background-color: #181818;
}

#delete-record-btn, #download-record-btn, #pause-record,
#record-uploaded, #play-record {
    height: 4.5vh;
    margin-right: 1vh;
}

#record-uploaded {
    padding: 0.7rem;
}

.progress-wrapper {
    width: 60%;
    height: 2.4rem;
}

.action-wrapper {
    width: 40%;
    align-items: flex-start;
}

.row-wrapper {
    width: 100%;
}