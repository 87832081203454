.arc-wrapper {
    position: relative;
}

.arc {
    position: absolute;
    border-radius: 100%;
    width: 2.4em;
    height: 2.4em;
}

.arc_start {
    transform: rotate(45deg);
    border: 4px solid;
}

.percentage {
    text-align: center;
    width: 2.4rem;
    height: 2.4rem;
    padding-top: 0.65rem;
    font-size: 0.7rem;
    color: #f4efe9;
}