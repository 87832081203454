.login-page {
  height: 100vh;
  background-color: #181818;
}

.login-form {
  min-height: 300px;
  color: #f4efe9 !important;
  background-color:#1a1b1b;
  width: 28vw;
  height: 32vw;
  margin-top: -5vh;
  border-radius: 1rem;
  padding-top: 4vh;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 1, 0.1), 0 0px 15px 5px rgba(0, 0, 0, 0.12);
}

.login-form input {
  background-color: #181818 !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.login-form input:focus {
  background-color: #181818 !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.login-form input:valid {
  background-color: #181818 !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #181818 inset !important;
  -webkit-text-fill-color: #f4efe9 !important;
}

.error-message {
  margin: 2vh;
  text-align: center;
}

#show-password-div {
  padding-left: 0.75vh;
  padding-bottom: 0.25vh;
}

#login-button {
  margin: 2vh;
  width: 11vw;
}

.version-txt{
  color: #4f5255; 
  margin-top: -20px;
  font-size: 14px !important;
}

.site-center-text{
  text-align: center !important;
  color: #4f5255; 
  margin-top: 0px;
  font-size: 14px !important;
}
