.input {
    width: 244px;
    height: 50px !important;
}

.inputBlack {
    height: 50px !important;
    background: #1D1E20 !important;
}

.inputBlack:focus {
    background: #1D1E20 !important;
}

.smallHeight {
    height: 40px !important;
}

.error {
    color: #ff5f5f;
    font-size: 13px;
}
