.modal {
  background-color: transparent !important;
  border-radius: none !important;
  border: none !important;
}

@media (min-width: 576px) {
  .modal {
    max-width: fit-content;
    margin: 1.75rem auto;
  }
}

.modal>div {
  background-color: #202020 !important;
  border-radius: 25px !important;
  border: none !important;
}

.modal input {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

.modal input:valid {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}

/* Autofill styles */
.modal input:-webkit-autofill,
.modal input:-webkit-autofill:hover,
.modal input:-webkit-autofill:focus,
.modal input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #272a2c inset !important;
  -webkit-text-fill-color: #f4efe9 !important;
}

/* Hover and focus styles */
.modal input:hover,
.modal input:active,
.modal input:focus {
  background-color: #272a2c !important;
  border: 1px solid #2c2e2e !important;
  color: #f4efe9 !important;
}


.content_container {
  padding: 21px 62px 37px;
  width: 745px;
  height: 650px;
  background: #181818 !important;
  border-radius: 25px !important;
  border: none !important;
}

.title {
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 34px;
  font-weight: bold;
  color: #DE684F;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subtitle {
  margin-top: 16px;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  color: #D9DBE9;
}

.hint {
  margin-top: 4px;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: italic;
  color: #D9DBE9;
}

.formContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  padding: 0 12px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.buttons>button {
  border-radius: 10px;
}

.stepper {
  margin-top: 11px;
  padding-bottom: 31px;
  border-bottom: 1px solid #D9DBE9;
}