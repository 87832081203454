.viewer {
	flex-direction: column;
    display: flex;
	margin-left: 0.5vw;
	margin-right: 0.5vw;
	margin-bottom: 1vh !important;
	background-color: #202020;
	/* border: 1px solid #373737; */
	border-radius: 5px !important;
	box-shadow: 0 4px 10px 1px rgba(0, 0, 1, 0.1), 0 0px 15px 5px rgba(0, 0, 0, 0.12);

}

/* .drag-and-drop-container {
	
} */

.broadcast-info {
	position: absolute;
	/* top: 65%; */
	/* left: 50%; */
	width: 250px;
	transform: translateY(-120%);
	color: #f2f2f2;
	text-shadow: 0 0 5px black;
}

.broadcast-info > span {
	display: inline-block;
	width: 50%;
	text-align: center;
}

.broadcast-info-extended {
	position: absolute;
	/* top: 65%; */
	/* left: 50%; */
	width: 350px;
	transform: translateY(-120%);
	color: #f2f2f2;
	text-shadow: 0 0 5px black;
}

.broadcast-info-extended > span {
	display: inline-block;
	width: 50%;
	text-align: center;
}
